import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "AWS MediaConvert と hls.js で動画配信サービスを構築しました",
  "date": "2020-11-27T08:00:06.000Z",
  "slug": "entry/2020/11/27/170006",
  "tags": ["medley"],
  "hero": "./2020_11_27.png",
  "heroAlt": "動画配信サービス"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、第一開発グループの矢野です。ジョブメドレー開発エンジニアとして、主にバックエンドを担当しています。`}</p>
    <p>{`直近では、ジョブメドレーが先月リリースした `}<strong parentName="p">{`「動画選考」`}</strong>{` 機能の開発プロジェクトに携わっており、動画ファイルのアップロード／配信環境の設計・実装を行っていました。`}</p>
    <p>{`今回のブログでは、この「動画選考」機能の開発に利用した `}<strong parentName="p">{`AWS Elemental MediaConvert`}</strong>{` サービスと、`}<strong parentName="p">{`hls.js`}</strong>{` という OSS ライブラリについて紹介したいと思います。`}</p>
    <h1>{`ジョブメドレーの「動画選考」機能`}</h1>
    <p>{`はじめに、今回リリースした「動画選考」機能について概要を紹介します。`}</p>
    <blockquote>
      <p parentName="blockquote">{`新型コロナウイルス感染拡大によって、対面での面接に不安を感じたり、公共交通機関の利用が難しくなったりすることにより、満足な転職活動ができなくなっている方もいらっしゃるかと思います。
このような課題を解決するために、ジョブメドレーではリアルタイムにオンラインで面接を行う「WEB 面接」と、事業者があらかじめ設定した質問に対して応募者が動画で回答を送る「動画選考」の 2 つの機能を提供開始いたしました。`}</p>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://job-medley.com/release/70/"
        }}>{`WEB 面接・動画選考機能のリリースのお知らせ`}</a></p>
    </blockquote>
    <p>{`動画選考（動画面接）は、近年増加傾向にあるオンライン選考の一種です。一般的に、求職者 / 就活生が PC ・スマートフォン等のカメラで、予め用意された設問に応じて動画を撮影し、企業に送ることで選考を行います。`}</p>
    <blockquote>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://job-medley.com/tips/detail/1151/#i3-1"
        }}>{`WEB 面接・動画選考とは？ 実施の流れ、使用ツール、マナー、注意点などを徹底解説！`}</a></p>
    </blockquote>
    <p>{`私たちジョブメドレーの動画選考では、事業所があらかじめ設定した質問に対して、求職者が回答動画を提出することができます。事業所も求職者も、動画で質問・回答を送ることで、書類だけでは伝わらない雰囲気や強みを相手に伝えることができます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201126/20201126150024.png",
      "alt": "20201126150024.png"
    }}></img>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://job-medley.com/release/70/"
        }}>{`WEB 面接・動画選考機能のリリースのお知らせ`}</a></p>
    </blockquote>
    <h1>{`動画配信サービスの設計ポイント`}</h1>
    <p>{`Web アプリでこのような動画配信サービスを開発する場合、「ユーザによる動画アップロード環境」と「ユーザへの動画の配信・再生環境」を提供する必要があります。`}</p>
    <p>{`ジョブメドレーで扱う動画は一般公開されるものではなく、公開条件も複雑です。`}</p>
    <p>{`よって今回は、この「動画アップロード／配信環境」を自サービス内に構築する方針をとり、以下のような動画まわりの設計ポイントについて検討・技術選定を行うことにしました。`}</p>
    <p>{`（もちろん、要件によっては YouTube や、法人向け動画配信プラットフォームを契約した方が手軽な場合もあるかと思います）`}</p>
    <ul>
      <li parentName="ul">{`動画の録画・撮影`}
        <ul parentName="li">
          <li parentName="ul">{`サポートしたい動画ファイルのフォーマットをどうするか`}</li>
          <li parentName="ul">{`Web アプリ内に録画機能を設けるか`}</li>
        </ul>
      </li>
      <li parentName="ul">{`動画のアップロード（ストレージ）`}
        <ul parentName="li">
          <li parentName="ul">{`動画ファイルのバリデーションで「動画ファイルの解析」を行うか`}</li>
          <li parentName="ul">{`動画ファイルのアップロード先（ストレージ）をどこにするか`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`動画のエンコード`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`動画ファイルのエンコード形式（H.264、HLS 等）をどうするか`}</li>
          <li parentName="ul">{`非同期エンコードの場合、ステータス検知・エラーハンドリングをどうするか`}</li>
        </ul>
      </li>
      <li parentName="ul">{`動画の配信（ダウンロード）`}
        <ul parentName="li">
          <li parentName="ul">{`配信形式（ダウンロード／ストリーミング）をどうするか`}</li>
          <li parentName="ul">{`暗号化をする場合、復号をどのように行うか`}</li>
          <li parentName="ul">{`動画ファイルの公開方法（アクセス制限）をどうするか`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`動画の再生`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Web ページ上で再生させるのか、その場合の表示・再生制御をどうするか`}</li>
          <li parentName="ul">{`ブラウザサポートをどこまでにするか、非対応・エラー時の制御をどうするか`}</li>
        </ul>
      </li>
    </ul>
    <p>{`今回は、上記の太字で記載した `}<strong parentName="p">{`「動画のエンコード」に MediaConvert`}</strong>{` を、`}<strong parentName="p">{`「動画の再生」に hls.js`}</strong>{` をそれぞれ採用しています。`}</p>
    <p>{`各項の詳細は省きますが、全体を通して大まかに、以下のフローで「動画アップロード → エンコード（変換）→ 配信・再生」を実現することにしました。`}</p>
    <ol>
      <li parentName="ol">{`ブラウザから Ajax で動画を S3 へアップロードする`}</li>
      <li parentName="ol">{`MediaConvert が動画を HLS 形式にエンコード（変換）する`}</li>
      <li parentName="ol">{`ブラウザで hls.js を使い動画を CloudFront からストリーミング形式で受信、再生する`}</li>
    </ol>
    <p>{`今回はこの「動画アップロード → エンコード（変換）→ 配信・再生」に焦点を絞り、MediaConvert と hls.js をどのように使ったのかを紹介します。`}</p>
    <h1>{`MediaConvert による HLS エンコード`}</h1>
    <p>{`AWS Elemental MediaConvert は、S3 との親和性が高いファイルベースの動画変換サービスです。自前で ffmpeg などを使って動画エンコードサーバを構築・管理することなく、スケーラブルな動画変換処理を手軽にシステムに組み込むことができます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201126/20201126150358.png",
      "alt": "20201126150358.png"
    }}></img>
    <blockquote>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://aws.amazon.com/jp/mediaconvert/"
        }}>{`AWS Elemental MediaConvert`}</a></p>
    </blockquote>
    <p>{`料金は出力する動画の再生時間に応じた従量課金です。AWS コンソールから GUI ベースでエンコード設定を作成したり、ジョブ（エンコード処理）を登録することができます。`}</p>
    <p>{`また、他 AWS サービス同様に API が提供されており、AWS CLI や各言語の SDK を使ってプログラムからエンコード処理を登録することができ、システム連携も容易です。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# CLI でエンコードジョブを登録する例`}</span>{`
$ aws --endpoint-url https://abcd1234.mediaconvert.region-name-1.amazonaws.com --region region-name-1 mediaconvert create-job --cli-input-json file://~/job.json`}</code></pre></div>
    <p>{`上記 CLI コマンドで下のようなエンコード設定を記載した JSON を使いジョブを作成すると、S3 上の動画ファイルをサクッとエンコードしてくれます。ジョブはキューイングされ、内部で並列処理されるため、大量のエンコード要求にも簡単に応じることができます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  ...
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"Settings"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"Inputs"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        # 入力元の S3 バケット上の動画ファイル key を指定
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"FileInput"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"s3://testcontent/720/example_input_720p.mov"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"OutputGroups"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"OutputGroupSettings"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"FileGroupSettings"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            # 出力先の S3 バケット key を指定
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"Destination"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"s3://testbucket/output"`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        # 動画・音声のエンコード設定を指定
        # ここで品質レベル毎に振り分けた複数のファイルを出力したり
        # サムネイル jpg を作成したりすることも可能
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"Outputs"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"VideoDescription"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` … `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"AudioDescriptions"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` … `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <blockquote>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://docs.aws.amazon.com/mediaconvert/latest/apireference/aws-cli.html"
        }}>{`AWSCLI を使用した AWSElemental MediaConvertCreateJob の例`}</a></p>
    </blockquote>
    <p>{`エンコードが完了したジョブは、cron + SDK などで API を介して定期チェックする他に、CloudWatch Events によるイベント監視 → Lambda で処理するようなこともできます。`}</p>
    <blockquote>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://docs.aws.amazon.com/ja_jp/mediaconvert/latest/ug/cloudwatch_events.html"
        }}>{`AWS Elemental MediaConvert による CloudWatch イベント の使用`}</a></p>
    </blockquote>
    <h2>{`なぜ動画を再エンコードするのか`}</h2>
    <p>{`通常、ユーザからアップロードされる動画ファイルは、既に何らかのコーデックで圧縮され `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.mp4`}</code>{` や `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.mov`}</code>{` などのコンテナフォーマットに変換されていることが殆どです。`}</p>
    <p>{`しかし Web ページで `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<video>`}</code>{` タグを使いこれら動画ファイルを再生しようとした場合、 `}<strong parentName="p">{`「動画フォーマットにブラウザが非対応だと再生できない」`}</strong>{` という環境依存問題があります。`}</p>
    <p><em parentName="p">{`ブラウザと動画フォーマットのサポート表`}</em></p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201126/20201126150540.png",
      "alt": "20201126150540.png"
    }}></img>
    <blockquote>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://en.wikipedia.org/wiki/HTML5_video#Browser_support"
        }}>{`HTML5 video > Browser support`}</a></p>
    </blockquote>
    <p>{`この問題に対応するため、多くの動画配信サービスでは、ユーザの動画を多くの環境で再生可能な MP4 コンテナフォーマット（H.264 + AAC コーデック）などの形式へ「再エンコード」しています。`}</p>
    <p>{`ジョブメドレーの動画選考では上記目的に加えて、動画閲覧時の回線・端末負荷を抑える `}<strong parentName="p">{`「HTTP ストリーミング形式」`}</strong>{` で動画を配信するために、アップロードされた動画を全て `}<strong parentName="p">{`HLS 形式`}</strong>{` にエンコードしています。`}</p>
    <h2>{`HLS - HTTP Live Streaming 形式`}</h2>
    <p>{`HLS は HTTP Live Streaming の略で、Apple 社の開発した規格です。HTTP ベースのストリーミング通信プロトコルで、細切れにした MP4 動画ファイルを分割ダウンロードさせることで動画のストリーミング配信を実現しています。`}</p>
    <p>{`HLS 形式にエンコードされた動画は `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.ts`}</code>{` という分割されたメディアファイル群と、 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.m3u8`}</code>{` という、メディアファイルの取得先や秒数などを記載したテキストファイルで構成されます。`}</p>
    <p><em parentName="p">{`.m3u8 ファイルの例（マニフェストファイル、プレイリストファイルとも）`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`#EXTM3U
#EXT-X-TARGETDURATION:10
#EXT-X-VERSION:3
#EXT-X-MEDIA-SEQUENCE:0
#EXT-X-PLAYLIST-TYPE:VOD
#EXTINF:9.97663,
media-0.ts
#EXTINF:9.97663,
media-1.ts
#EXTINF:7.10710,
media-2.ts
#EXT-X-ENDLIST`}</code></pre></div>
    <blockquote>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://tools.ietf.org/html/rfc8216"
        }}>{`RFC 8216: HTTP Live Streaming`}</a></p>
    </blockquote>
    <p>{`HLS は他のストリーミング形式と比較して、ライブ配信 / VOD どちらにも対応可能なこと、対応ブラウザが多いこと、専用の配信サーバを使わずに配信可能なことなどから、近年の動画配信サービスで広く利用されています。`}</p>
    <p>{`Web エンジニアの視点から見ても、 HTTP ベースなためキャッシュや HTTPS 暗号化など、既存 Web 技術と掛け合わせることが想像しやすく、扱いやすい印象でした。`}</p>
    <h2>{`MediaConvert の HLS エンコードジョブ設定`}</h2>
    <p>{`実際にプログラムから API 経由で HLS エンコードジョブを登録する際の設定 JSON は、以下のように GUI でジョブテンプレートを作成して確認することができます。`}</p>
    <undefined><img {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201126/20201126150659.png",
        "alt": "20201126150659.png"
      }}></img>
      <img {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201126/20201126150726.png",
        "alt": "20201126150726.png"
      }}></img>
      <img {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201126/20201126150804.png",
        "alt": "20201126150804.png"
      }}></img>
      <img {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201126/20201126150827.png",
        "alt": "20201126150827.png"
      }}></img></undefined>
    <p>{`この「 JSON を表示」で、前述した CLI コマンド `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mediaconvert create-job --cli-input-json`}</code>{` に渡せる JSON が表示されます。実装の際にはこちらを参考にしながら、`}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/ja_jp/mediaconvert/latest/ug/what-is.html"
      }}>{`ユーザーガイド`}</a>{` を参照して利用したい機能にあわせた設定を追加していくことをおすすめします。`}</p>
    <h2>{`注意点・つまづいたポイント`}</h2>
    <ul>
      <li parentName="ul">{`利用前に IAM で MediaConvert 用ロールの設定が必要です`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/ja_jp/mediaconvert/latest/ug/iam-role.html"
            }}>{`ステップ 3. IAM 権限の設定`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`AWS コンソールの Service Quotas > AWS サービス > AWS Elemental MediaConvert から確認できますが、エンコード並行処理の同時実行数上限は 20 になっています`}
        <ul parentName="li">
          <li parentName="ul">{`AWS ルートアカウント 1 つにつき 1 サービスが割当てられるので、これを増やしたい場合は申請が必要です`}</li>
        </ul>
      </li>
      <li parentName="ul">{`エンコードジョブをキューイングする「キュー」を作成して、ジョブの登録時に選べるのですが、上記した「並行処理の同時実行数上限」はこの「キュー」毎に均等に振り分けられます`}
        <ul parentName="li">
          <li parentName="ul">{`例えば「本番キュー」と「検証キュー」の 2 つのキューを作成した場合、それぞれの並行処理の同時実行数上限は 10 ずつになるので注意してください`}</li>
        </ul>
      </li>
      <li parentName="ul">{`マニフェスト期間形式（Manifest duration format）に整数（INTEGER）を指定していると、iOS Safari で「動画の実際の再生時間と、再生プレイヤーのシークバーに表示される合計時間にズレが生じる」問題がありました`}
        <ul parentName="li">
          <li parentName="ul">{`浮動小数点（FLOATING POINT）に変更することで対応しました、マニフェストファイルに出力される各 `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`.ts`}</code>{` ファイルの長さが、浮動小数点 → 整数に変換され切り上げられることでズレが生じているようでした`}</li>
        </ul>
      </li>
    </ul>
    <h1>{`hls.js による HLS 動画の再生制御`}</h1>
    <p>{`MediaConvert により HLS 形式にエンコードされた動画を、Web ブラウザで再生するために必要なのが、hls.js です。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201126/20201126151007.png",
      "alt": "20201126151007.png"
    }}></img>
    <blockquote>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://github.com/video-dev/hls.js/"
        }}>{`video-dev/hls.js`}</a></p>
    </blockquote>
    <p>{`実は HLS によるストリーミング配信は、現状 `}<strong parentName="p">{`Safari など限られたブラウザでしかネイティブでサポートされていません。`}</strong></p>
    <blockquote>
      <p parentName="blockquote">{`ref. `}<a parentName="p" {...{
          "href": "https://caniuse.com/http-live-streaming"
        }}>{`https://caniuse.com/http-live-streaming`}</a></p>
    </blockquote>
    <p>{`この HLS 動画を Safari 以外の Google Chrome や IE11 などの主要ブラウザで再生可能にするため、hls.js が利用されています。内部的には、非対応ブラウザ環境において、ブラウザの `}<a parentName="p" {...{
        "href": "https://w3c.github.io/media-source/"
      }}>{`MediaSource 拡張`}</a>{` を使って HLS 動画を再生する仕様になっています。`}</p>
    <h2>{`Video.js との比較`}</h2>
    <p>{`似たようなライブラリに `}<a parentName="p" {...{
        "href": "https://github.com/videojs/video.js"
      }}>{`Video.js`}</a>{` というものもあり、導入を迷ったのですが …`}</p>
    <ul>
      <li parentName="ul">{`Video.js は UI もセットになった「 HLS に対応した再生プレイヤー」ライブラリ`}
        <ul parentName="li">
          <li parentName="ul">{`HLS 対応以外にも、字幕や章分けなど機能が豊富`}</li>
        </ul>
      </li>
      <li parentName="ul">{`hls.js はブラウザ標準の `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<video>`}</code>{` タグで HLS に対応することだけを目的にした「 HLS クライアント」ライブラリ`}
        <ul parentName="li">
          <li parentName="ul">{`UI などはなく、動画再生プレイヤーはブラウザ標準のまま`}</li>
        </ul>
      </li>
    </ul>
    <p>{`…と、上記のように hls.js の方がシンプルにやりたいことを実現できるため、今回は hls.js を採用しました。`}</p>
    <p>{`GitHub のスター数は先発の Video.js の方が多いのですが、hls.js も開発は活発で、日本では `}<a parentName="p" {...{
        "href": "https://gunosy.com/"
      }}>{`グノシー`}</a>{` さん、世界的には `}<a parentName="p" {...{
        "href": "https://www.ted.com/"
      }}>{`TED`}</a>{` や `}<a parentName="p" {...{
        "href": "https://twitter.com/"
      }}>{`Twitter`}</a>{` でも採用されており、十分実績があるかと思います。`}</p>
    <h2>{`hls.js による実装`}</h2>
    <p>{`基本的には README の `}<a parentName="p" {...{
        "href": "https://github.com/video-dev/hls.js#getting-started"
      }}>{`Getting Started`}</a>{` の通りで実装できます。一部 README のサンプルコードから抜粋して解説すると...`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` video `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getElementById`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"video"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` videoSrc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`Hls`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`isSupported`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` hls `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Hls`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  hls`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`loadSource`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`videoSrc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  hls`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`attachMedia`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`video`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  hls`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`Hls`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Events`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`MANIFEST_PARSED`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    video`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`play`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`上記 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Hls.isSupported()`}</code>{` の分岐で、HLS をネイティブサポートしていないブラウザの処理を実装しています。
本来 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<video>`}</code>{` の `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`src`}</code>{` 属性にセットするべき `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.m3u8`}</code>{` ファイルの URL へ `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`hls.loadSource()`}</code>{` でアクセスさせ、クライアントから XHR リクエストを飛ばします。その後 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`hls.attachMedia()`}</code>{` でインスタンスを DOM 上の `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<video>`}</code>{` タグに紐づけています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`video`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`canPlayType`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'application/vnd.apple.mpegurl'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    video`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`src `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` videoSrc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    video`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'loadedmetadata'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      video`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`play`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`上記の分岐が iOS Safari など、HLS 動画をネイティブサポートしているブラウザ向けの処理です。単純に `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.m3u8`}</code>{` への URL を `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<video>`}</code>{` タグの `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`src`}</code>{` へ付与しているだけですね。`}</p>
    <p>{`（サンプルコードでは、マニフェストファイルのロード後に自動再生させるようになっているようです）`}</p>
    <h2>{`注意点・つまづいたポイント`}</h2>
    <ul>
      <li parentName="ul">{`hls.js クライアントが取得する HLS 動画ファイル群は、CORS ヘッダで GET リクエストを許可された環境に設置する必要があります`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`.m3u8`}</code>{` マニフェストファイルをアプリの API などから返却する場合、Content-Type を `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`application/x-mpegURL`}</code>{` にして渡す必要があります`}</li>
      <li parentName="ul">{`iOS Safari などの hls.js 非対応ブラウザ向けの実装を意識する必要があります`}
        <ul parentName="li">
          <li parentName="ul">{`hls.js による制御が複雑になるケースでは、同じような制御を hls.js 非対応ブラウザ向けに実装できるか？をイメージできないと手戻りが発生しそうです`}</li>
        </ul>
      </li>
    </ul>
    <p>{`この他、フロントエンドでは `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<video>`}</code>{` タグのブラウザ毎の挙動や、表示の違いに時間がかかりました。（ある程度予想はしていましたが、やはりメディアの取り扱いは難しい…）`}</p>
    <p>{`hls.js 自体は導入も手軽で、サクッと HLS 動画のマルチブラウザ対応が実現でき、とても使いやすかったです。@types も存在するので、TypeScript 環境でも難なく実装できました。`}</p>
    <p>{`SSR や HLS + AES-128 の再生にも対応しているので、興味のある方は一度 `}<a parentName="p" {...{
        "href": "https://hls-js.netlify.app/api-docs/"
      }}>{`公式ドキュメント`}</a>{` を確認してみてください。`}</p>
    <h1>{`おわりに`}</h1>
    <p>{`従来、動画配信サービスを構築する場合、ffmpeg を載せたエンコードサーバや、ストリーミング配信サーバを別建てして、負荷に応じてスケールさせて…のような設計が必要だったかと思います。`}</p>
    <p>{`今回、MediaConvert をはじめとした AWS サービスと hls.js を利用することで、手軽に、スケーラブルな動画エンコード／HTTP ストリーミング配信環境を構築することができました。`}</p>
    <p>{`ジョブメドレーの動画選考はまだリリースしたばかりですので、今後反響を見ながら、さらなる改善を重ねていけたらと思います。最後までお読みいただきありがとうございました。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      